import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Link from '../components/Common/Link'
import List from '../components/Common/List'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const BimImplementationInTheCompany: React.FC = () => {
  return (
    <>
      <SEO
        title="BIM Implementation"
        description="Success of BIM technology implementation depends on your specific goals, detailed plan, roles, and realistic milestones."
        keywords="BIM strategy, BIM plan, BIM Execution Plan, Common Data Environment, BIM software, BIM models, BIM activities, BIM training."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          BIM Implementation in the Company
        </Typography>
        <Typography variant="body1">
          It was clearly stated in the{' '}
          <Link href="/what-is-bim/" target="_blank" rel="nofollow noreferrer">
            previous article
          </Link>{' '}
          that Building Information Modeling (BIM) is a model-based technology
          linked with a database of project information, and the technology is
          based on three pillars: 3D, Information, and Collaboration. So, the
          success of the implementation of BIM technology depends on how common
          the understanding of{' '}
          <Link href="/what-is-bim/" target="_blank" rel="nofollow noreferrer">
            BIM basics
          </Link>{' '}
          and their benefits is among all participants and how comprehensively
          approached it is within the company.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/bim-implementation-in-the-company/preview.jpg"
            alt="BIM Implementation"
            title="BIM Implementation"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Overview
        </Typography>
        <Typography variant="body1">
          It was always challenging to introduce a new idea or product into any
          environment and achieve distribution in production.
        </Typography>
        <Typography variant="body1">
          Modern society seems to believe that mobile phones have always
          existed, and only our parents and grandparents probably remember the
          painful addiction to them. But I still remember the first difficult
          lessons I took in AutoCAD at university. Imagine how difficult it was
          for designers who were accustomed to drawing on paper to use 2D CAD
          software for construction in production. However, after only a few
          decades, no one goes out without a smartphone, and of course, no one
          produces drawings on paper.
        </Typography>
        <Typography variant="body1">
          The sudden arrival of Building Information Modeling (BIM) led to a
          split between those who, without hesitation, accepted the new
          regulations and those who are used to working the old way and simply
          afraid of change. But even in this case, a complete transition of the
          design and construction industry to BIM is inevitable.
        </Typography>
        <Typography variant="h2" color="blue">
          What, Where, Who, How?
        </Typography>
        <Typography variant="body1">
          Answering the questions 'what', 'where', and 'who' will allow us to
          answer the 'how' to successfully implement, ensuring that it takes
          root from the very beginning.
        </Typography>
        <Typography variant="h2" color="blue">
          What are the expectations?
        </Typography>
        <Typography variant="body1">
          In other words, what goals does the company want to achieve using BIM?
          Automated clash analysis, measurement of the number of facility
          elements, and cost control can be good places to start. The ambitious
          goal, such as eliminating the use of paper drawings on the
          construction site, can be achieved after a number of successfully
          completed projects in BIM.
        </Typography>
        <Typography variant="body1">
          For purposes and objectives determining BIM uses, it is worth
          referring to the BIM Use Classification System and Structure
          <sup>
            <AnchorLink
              to="/bim-implementation-in-the-company/#sup1"
              className="has-text-primary"
              stripHash
            >
              1
            </AnchorLink>
          </sup>
          .
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={680}
            src="../assets/images/articles/bim-implementation-in-the-company/image-1.png"
            alt="Calculation of project budget and tender scopes according to the BIM model"
            title="Calculation of project budget and tender scopes according to the BIM model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Figure 1. Calculation of project budget and tender scopes according
            to the BIM model
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Where should BIM be placed in?
        </Typography>
        <Typography variant="body1">
          In an architecture studio, an engineering firm, a general contractor,
          or other type of construction company?
        </Typography>
        <Typography variant="body1">
          From the type of construction company depends on the 'what' and 'how'
          answers, especially the choice of software. For example, it can be
          Customer platforms or construction project management software for
          small businesses. Additionally, BIM could be the customers'
          requirement. Therefore, it is necessary to address these requirements,
          which should be recorded in the Employer's Information Requirements
          (EIR) in general practice.
        </Typography>
        <Typography variant="h2" color="blue">
          Who will implement?
        </Typography>
        <Typography variant="body1">
          An external consulting firm or the company will do it on its own. The
          organization's size, previous expertise in BIM, the availability of
          staff for ongoing projects, etc., could influence this decision.
        </Typography>
        <Typography variant="h2" color="blue">
          How to implement and how achieve the diffusion of BIM in production?
        </Typography>
        <Typography variant="body1">
          Bypassing the main stages of implementation: Preliminary (Planning)
          Phase, Training Phase, Application Phase.
        </Typography>
        <Typography variant="h2" color="blue">
          Implementation Phases
        </Typography>
        <Typography variant="h3">1. Preliminary (Planning) phase</Typography>
        <Typography variant="body1">
          Before a company embarks on the implementation, it is important to
          ensure that the entire team understands the fundamental principles of
          BIM and the process of transitioning to new ways of working and
          collaborating. The enhancement of the level of proficiency within the
          organization and the implementation of a strategy are both factors
          that contribute to this objective.
        </Typography>
        <List className="pl-4">
          <li>
            {`Increase the competence\n`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              To achieve this, it is suggested that a brief introduction to the
              fundamentals of BIM technology be given to those involved in the
              design and construction phases.
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The course format can be in the form of either offline conferences
              or online webinars. During the meetings, it is necessary to
              acquaint employees with industry trends and the benefits of
              technology for each role: customer, designer, builder, and others.
              The primary objective of this stage is to prepare (engage) the
              employees for the forthcoming modifications in the company's
              operations.
            </Typography>
          </li>
          <li>
            {`Develop a strategy`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              An implementation strategy should include a detailed plan, roles,
              expectations, and realistic milestones. It is important to
              coordinate the strategy and all subsequent BIM activities with the
              initiative group within the company. The implementation plan might
              be corrected after the practical education that follows. A BIM
              implementation strategy is a comprehensive document that serves as
              the central reference point for all participants in the
              implementation process. Despite its complexity, it must be simple
              and understandable for everyone. It is recommended to choose a
              visual format for its presentation and always be unobtrusively
              visible.
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The introduction to BIM fundamentals and strategy development may
              be conducted simultaneously.
            </Typography>
          </li>
        </List>
        <Typography variant="h4">Training Phase</Typography>
        <Typography variant="body1">
          So, here are some practical tips:
        </Typography>
        <List className="pl-4">
          <li>
            {`Define BIM software for construction;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Considering software features like interoperability and
              collaboration options, license term and type, update method, and
              expenses. Therefore, for designers, it pertains to specialized
              software, whereas for construction management, it pertains
              primarily to builders’ project management software and Mobile Apps
              for Construction.
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={680}
                src="../assets/images/articles/bim-implementation-in-the-company/image-2.png"
                alt="SIGNAX INSPECTION – remote project management software"
                title="SIGNAX INSPECTION – remote project management software"
                placeholder="blurred"
              />
              <Typography variant="body2" color="gray">
                Figure 2. SIGNAX INSPECTION – remote project management software
              </Typography>
            </div>
          </li>
          <li>
            {`Establish study groups;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Classes of architects, engineers, project managers, and
              construction site representatives.
            </Typography>
          </li>
          <li>
            {`Choose a test project;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The training should be carried out on a test project, but not on
              the current one. A good choice is one of the recently completed
              projects.
            </Typography>
          </li>
          <li>
            {`Draw up a program and schedule of training;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Training should be part of the employees' work time, not their
              personal time.
            </Typography>
          </li>
          <li>
            {`Fix frequently asked questions and topics of concern during the education;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              This will aid in the planning of the forthcoming training program.
            </Typography>
          </li>
          <li>
            {`Analyze with the implementation customer after the training is finished;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              It may be necessary to correct the plan.
            </Typography>
          </li>
          <li>
            {`Establish the requirements for BIM models.`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              After completing the training, the workers can clearly discern the
              specific applications of BIM for the particular project,
              evaluating the amount of effort and time required.
            </Typography>
          </li>
        </List>
        <Typography variant="h3">2. Application Phase</Typography>
        <Typography variant="body1">
          If a technology is not implemented, it is not considered to be
          implemented. Therefore, the concluding phase of the implementation
          procedure entails the development of the actual project using BIM
          technology.
        </Typography>
        <Typography variant="body1">
          Here are the steps for direct BIM application on a project:
        </Typography>
        <List className="pl-4">
          <li>
            {`Define the first project;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Such a project is commonly referred to as a pilot project. When
              selecting it, it is imperative to consider the impact of the new
              approach on the development timeline, as it may result in a delay.
              And the company's management should be aware of this.
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              As soon as the pilot project is defined, all the following steps
              must be mentioned in the project BIM Execution Plan (BEP).
            </Typography>
          </li>
          <li>
            {`Define project team;`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              A team like that would have to include representatives from the
              owner side and all the relevant stakeholders: contractors,
              facility managers, engineers, and others. There should also be a
              clearly defined BIM coordination leader.
            </Typography>
          </li>
          <li>{`Set a list of specific goals for the project;`}</li>
          <li>
            {`Set up the implementation infrastructure.`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              It is also known as Common Data Environment (CDE)
              <sup>
                <AnchorLink
                  to="/bim-implementation-in-the-company/#sup2"
                  className="has-text-primary"
                  stripHash
                >
                  2
                </AnchorLink>
              </sup>
              . This includes setting up quality control procedures and defining
              communication and information exchange methods.
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              At the end of the project, analyze the results and capture case
              studies and best practices for follow-up. Expand your goals by
              following the strategy.
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={680}
                src="../assets/images/articles/bim-implementation-in-the-company/image-3.jpg"
                alt="Digital Engineer on site works with help of BIM model"
                title="Digital Engineer on site works with help of BIM model"
                placeholder="blurred"
              />
              <Typography variant="body2" color="gray">
                Figure 3. Digital Engineer on site works with help of BIM model
              </Typography>
            </div>
          </li>
        </List>
        <Typography variant="h2" color="blue">
          Implementation approach is essential
        </Typography>
        <Typography variant="body1">
          Many companies work with experienced specialists but do it the old
          way. There is an acute problem of rejection of the new methodology.
          Experienced professionals have always been a valuable resource.
          Therefore, the project's success and the company's competitiveness as
          a whole depend on its outcome, as well as the possible loss of
          employee productivity.
        </Typography>
        <Typography variant="body1">
          Moreover, it is crucial to consider the inevitable process of
          adoption, which involves the psychological processes an individual
          goes through. To facilitate adaptation, two conditions must be taken
          into account:
        </Typography>
        <List className="pl-4">
          <li>New work processes should be exciting for employees;</li>
          <li>
            Implementation activities should not interfere with the main tasks
            if they do not entirely coincide.
          </li>
        </List>
        <Typography variant="body1">
          After successful examples of the application of technology in design,
          it is important to expand its implementation to related areas,
          especially in construction management.
        </Typography>
        <Typography variant="body1">
          Last but not least, the words "inspire" and "force" are synonyms for
          "implement." So, it's up to the people in charge to make the process
          exciting and successful.
        </Typography>
        <Typography variant="body1" size={16} id="sup1" className="mb-0">
          <sup className="mr-1">1</sup>
          BIM Project Execution Planning Guide, Version 3.0, Computer Integrated
          Construction Program, Penn State,{' '}
          <Link
            href="https://psu.pb.unizin.org/bimprojectexecutionplanning"
            target="_blank"
            rel="nofollow noreferrer"
          >
            https://psu.pb.unizin.org/bimprojectexecutionplanning
          </Link>
        </Typography>
        <Typography variant="body1" size={16} id="sup2" className="mt-0">
          <sup className="mr-1">2</sup>
          “Common Data Environments”, NBS, April 5, 2019.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimImplementationInTheCompany
